import React from 'react';

import styled from 'styled-components';

import Layout from '../components/layout';

const Container = styled.div`
  padding: 2rem;
  text-align: center;
  width: 100%;
`;

export default function Officers() {
  return (
    <Layout>
      <Container>
        <h5 className="officer-title">Worshipful Master</h5>
        <h6 className="officer-name">Wor. Emmanuel R. Fernandes</h6>

        <h5 className="officer-title">Senior Warden</h5>
        <h6 className="officer-name">Bro. Robert C. Franklin, Jr.</h6>

        <h5 className="officer-title">Junior Warden</h5>
        <h6 className="officer-name">Bro. David P. Saucier</h6>

        <h5 className="officer-title">Treasurer</h5>
        <h6 className="officer-name">Wor. Kenneth T. Ainsley</h6>

        <h5 className="officer-title">Secretary</h5>
        <h6 className="officer-name">R.W. Wally C. Therrien</h6>

        <h5 className="officer-title">Asst. Treasurer</h5>
        <h6 className="officer-name">Wor. Scott T. Brooks</h6>

        <h5 className="officer-title">Asst. Secretary</h5>
        <h6 className="officer-name">R.W. August R. Cote, Sr.</h6>

        <h5 className="officer-title">Chaplain</h5>
        <h6 className="officer-name">R.W. Charles E. Katsanos</h6>

        <h5 className="officer-title">Marshal</h5>
        <h6 className="officer-name">Wor. William W. Taylor</h6>

        <h5 className="officer-title">Senior Deacon</h5>
        <h6 className="officer-name">Bro. Brian M. Fernandes</h6>

        <h5 className="officer-title">Junior Deacon</h5>
        <h6 className="officer-name">Bro. William J. Duffy</h6>

        <h5 className="officer-title">Senior Steward</h5>
        <h6 className="officer-name">Bro. Ryan A. Klein</h6>

        <h5 className="officer-title">Junior Steward</h5>
        <h6 className="officer-name">Bro. John A. Cabral, Sr.</h6>

        <h5 className="officer-title">Inside Sentinel</h5>
        <h6 className="officer-name">Jose F. Monteiro</h6>

        <h5 className="officer-title">Tyler</h5>
        <h6 className="officer-name">Bro. Ronald A. George</h6>

        <h5 className="officer-title">Organist</h5>
        <h6 className="officer-name">Bro. Thomas G. Stiles</h6>

        <h5 className="officer-title">Electrician</h5>
        <h6 className="officer-name">Bro. David DeMello</h6>
      </Container>
    </Layout>
  );
}
